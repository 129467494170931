export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAdnukgXcwvzm6bc05g4CwGThu3ncLKQKs',
    authDomain: 'tingsys-218908.appspot.com',
    databaseURL: 'https://jsa-angular6.firebaseio.com',
    projectId: 'tingsys-218908',
    storageBucket: 'tingsys-218908.appspot.com',
    messagingSenderId: '1060860257978'
  }
};
