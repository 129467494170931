export class SystemConstants {
    public static CURRENT_USER = 'CURRENT_USER';
    public static ADMIN = 'Admin';
    public static BASE_API = 'https://api.tingconnect.com/';
    public static CHECKIN_API = 'https://sit.recognition.tingconnect.com/';
    public static REPORT_CHECK_IN_DOMAIN = 'https://report.tingconnect.com';
    public static pageIndex = 1;
    public static pageSize = 20;
    public static pageDisplay = 5;
    public static OperationCenterSelected = 'OperationCenterSelected';
    public static BuildingBlock = 'BuildingBlock';
    public static OperationCenters = 'OperationCenters';
    public static Departments = 'Departments';
    public static IDProject = '';
    public static city = '';
    public static lat = 21.022703;
    public static lng = 105.8194541;
    public static imageNoAvaliable = '/assets/images/default_image.png';
    public static pag_First = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.123047 7.87689V0.123047H1.41535V7.87689H0.123047ZM2.38459 3.99997L7.87689 0.123047V7.87689L2.38459 3.99997Z" fill="#C6CACC"/>
  </svg>
  `;

    public static pag_prev = `<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.23242 0.477539V9.52369L0.12473 5.00062L7.23242 0.477539Z" fill="#C6CACC"/>
  </svg>
  `;

    public static pag_last = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.58459 7.87689H7.87689V0.123047H6.58459V7.87689ZM0.123047 7.87689L5.61535 3.99997L0.123047 0.123047V7.87689Z" fill="#78909C"/>
  </svg>
  `;

    public static pag_next = `<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.767578 9.52246L0.767578 0.476308L7.87527 4.99938L0.767578 9.52246Z" fill="#C6CACC"/>
  </svg>
  `;
  public static pag_Maxsize = 10;

  public static URL_PROVIDER = `https://dev.provider.tingconnect.vn/login`;
  public static CURRENT_ACCOUNT = 'CURRENT_ACCOUNT';
  public static ENCODE_API = 'https://zenkiz.com/ting/getcrypt.php';
  public static KEY = `MIGfMA0GCSq`;

}
