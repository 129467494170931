import { Injectable } from '@angular/core';
import { Http, Response, Headers, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import { SystemConstants } from '../common/system.constants';
import { AuthenService } from './authen.service';
import { NotificationService } from './notification.service';
import { UtilityService } from './utility.service';
import { Observable } from 'rxjs/Observable';
import { MessageContstants } from '../common/message.constants';
import { RequestOptions } from "@angular/http";
import * as moment from 'moment';

interface FilterCheckInParams {
  team_id: number;
  working_day: moment.Moment;
}

@Injectable()
export class DataService {

  private headers: Headers;
  constructor(private _http: Http, private _router: Router, private _authenService: AuthenService, private _notificationService: NotificationService,
    private _utilityService: UtilityService) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  get(uri: string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.get(SystemConstants.BASE_API + uri, { headers: this.headers }).map(this.extractData);
  }
  getAvatar(uri: string) {
    return this._http.get(SystemConstants.BASE_API + uri, null).map(this.extractData1);
  }
  post(uri: string, data?: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.BASE_API + uri, data, { headers: this.headers }).map(this.extractData);
  }
  postCalculatorFee(uri: string, data?: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.BASE_API + uri, data, { headers: this.headers }).map(this.extractDataCalculator);
  }
  postUserRole(uri: string, szLoginName: string, data?: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.BASE_API + uri + "/" + szLoginName, data, { headers: this.headers }).map(this.extractData);
  }
  postLogout(uri: string, data?: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.BASE_API + uri, data, { headers: this.headers }).map((res: Response) => { });
  }
  postRegister(uri: string, data?: any) {
    return this._http.post(SystemConstants.BASE_API + uri, data).map((res: Response) => { });
  }
  put(uri: string, data?: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.put(SystemConstants.BASE_API + uri, data, { headers: this.headers }).map(this.extractData);
  }
  putAccount(uri: string, data: any, loginName: any, key: any, nemail: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    let stringQuery = SystemConstants.BASE_API + uri + "?loginname=" + loginName + "&" + key + "=" + nemail;
    return this._http.put(stringQuery, data, { headers: this.headers }).map(this.extractData);
  }
  delete(uri: string, key: string, id: string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.delete(SystemConstants.BASE_API + uri + "/?" + key + "=" + id, { headers: this.headers }).map(this.extractData);
  }
  deleteToken(uri: string, tokenFireBase: string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    let options = new RequestOptions({
      headers: this.headers,
      body: tokenFireBase
    });
    return this._http.delete(SystemConstants.BASE_API + uri, options).map(this.extractData);
  }
  deleteById(uri: string, id : string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.delete(SystemConstants.BASE_API + uri + "/" + id, { headers: this.headers }).map(this.extractData);
  }
  deleteAccount(uri: string, key: string, value: string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.delete(SystemConstants.BASE_API + uri + "?" + key + "=" + value, { headers: this.headers }).map(this.extractData);
  }
  deleteAccountTingConnect(uri: string, roleName: string, tingId: string) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.delete(SystemConstants.BASE_API + uri + "?tingId=" + tingId + '&' + "roleName=" + roleName, { headers: this.headers }).map(this.extractData);
  }
  postFile(uri: string, data?: any) {
    let newHeader = new Headers();
    newHeader.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.BASE_API + uri, data, { headers: newHeader }).map(this.extractData);
  }
  downloadTimekeepingReport(uri, data: any) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.post(SystemConstants.CHECKIN_API + uri, data, { 
      headers: this.headers, responseType: ResponseContentType.Blob 
    }).map(this.extractData);
  }
  getTeamsOfOperator(operatorId: number) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    return this._http.get(`${SystemConstants.CHECKIN_API}api/checkin/operator/${operatorId}/teams`).map(this.extractData);
  }
  getTimeKeepingOfTeam(params: FilterCheckInParams) {
    this.headers.delete("Authorization");
    this.headers.append("Authorization", "Bearer " + this._authenService.getLoggedInUser().token);
    const { team_id, working_day } = params;
    /**
     * format date only to ISOString
     */
    const wkDate = working_day.format('YYYY-MM-DD');
    return this._http.get(`${SystemConstants.CHECKIN_API}api/checkin/team/${team_id}?working_day=${wkDate}`).map(this.extractData);
  }
  private extractData(res: Response) {
    if (res.text()) {
      let body = res.json();
      return body;
    }
    return {};
  }
  private extractData1(res: Response) {
    if (res.text()) {
      let body = res.text();
      return body;
    }
    return {};
  }
  private extractDataCalculator
  (res: Response) {
    // debugger
    if (res.text()) {
      let body = res.text();
      return body;
    }
    return {};
  }
  public handleError(error: any) {
    // debugger
    if (error.status == 401) {
      localStorage.removeItem(SystemConstants.CURRENT_USER);
      this._notificationService.printErrorMessage(MessageContstants.LOGIN_AGAIN_MSG);
      this._utilityService.navigateToLogin();
      window.location.reload();
    }
    else if (error.status == 403) {
      localStorage.removeItem(SystemConstants.CURRENT_USER);
      this._notificationService.printErrorMessage(MessageContstants.FORBIDDEN);
      this._utilityService.navigateToLogin();
    }
    else if (error.status == 404) {
      this._notificationService.printErrorMessage(MessageContstants.FORBIDDEN);
      this._utilityService.navigateToLogin();
    }
    else {
      let errMsg = JSON.parse(error._body); // .Message;
      this._notificationService.printErrorMessage(errMsg);
      return Observable.throw(errMsg);
    }
  }
  public handleErrorText(error: any) {
    if (error.status == 401) {
      localStorage.removeItem(SystemConstants.CURRENT_USER);
      this._notificationService.printErrorMessage(MessageContstants.LOGIN_AGAIN_MSG);
      this._utilityService.navigateToLogin();
      window.location.reload();
    }
    else if (error.status == 403) {
      localStorage.removeItem(SystemConstants.CURRENT_USER);
      this._notificationService.printErrorMessage(MessageContstants.FORBIDDEN);
      this._utilityService.navigateToLogin();
    }
    else if (error.status == 404) {
      this._notificationService.printErrorMessage(MessageContstants.FORBIDDEN);
      this._utilityService.navigateToLogin();
    }
    else {
      let errMsg = error._body;
      this._notificationService.printErrorMessage(errMsg);

      return Observable.throw(errMsg);
    }
  }
}
