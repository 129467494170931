import { SessionData } from './SessionData';

export class MessageContstants {
    public static get SYSTEM_ERROR_MSG() {
        return SessionData.Language === 'vi' ? 'Có lỗi kết nối đến máy chủ' : 'There was an error connecting to the server';
    }
    public static get CONFIRM_DELETE_MSG() {
        return SessionData.Language === 'vi' ? 'Bạn có chắc chắn muốn xóa ?' : 'Are you sure you want to delete this ?';
    }
    // public static get CONFIRM_DELETE_VEHICLES_MSG(){
    //     return SessionData.Language === 'vi' ? `Bạn có chắc chắn muốn xóa phương tiện ?` : 'Are you sure you want to delete vehicles ?';
    // };
    public static get LOGIN_AGAIN_MSG() {
        return SessionData.Language === 'vi' ? 'Bạn hết phiên đăng nhập. Mời đăng nhập lại.' : 'You are out of login. Please login again';
    }
    public static get CREATED_OK_MSG() {
        return SessionData.Language === 'vi' ? 'Thêm mới thành công' : 'Created successful';
    }
    public static get CREATED_NULL_MSG() {
        return SessionData.Language === 'vi' ? 'Vui lòng không để trống trường thông tin' : 'You have to fill all the required field';
    }
    public static get FEEDBACK_OK_MSG() {
      return SessionData.Language === 'vi' ? 'Đã gửi phản hồi thành công' : 'Feedback send successfully';
    }
    public static get CREATED_INVALID_MSG() {
      return SessionData.Language === 'vi' ? 'không chính xác' : 'invalid';
    }
    public static get CREATED_INVALID_MSG_NULL() {
      return SessionData.Language === 'vi' ? 'không được rỗng' : 'can\'t be empty';
    }
    public static get CREATED_FAIL_MSG() {
        return SessionData.Language === 'vi' ? 'Biển số xe đã tồn tại' : 'Number sign is existing';
    }
    public static get CHANGED_PASSWORD_OK() {
      return SessionData.Language === 'vi' ? 'Đổi mật khẩu thành công' : 'Change password successfully';
    }
    // public static get CREATED_FAIL_MSG(){
    //     return SessionData.Language === 'vi' ? 'Biển số xe phải chính xác' : 'Number sign is incorrect';
    // };
    public static get INVALID_DATE_MSG() {
      return SessionData.Language === 'vi' ? 'Ngày kết thúc phải xảy ra sau ngày bắt đầu' : 'End date must be after the start date';
    }
    public static get INVALID_DATE_MSG2() {
      return SessionData.Language === 'vi' ? 'Ngày hoạt động phải xảy ra sau hoặc cùng ngày đăng ký'
        : 'Active date must be after or same as register date';
    }

    public static get INVALID_DATE_MSG3() {
      return SessionData.Language === 'vi' ? 'Ngày hết hạn phải theo sau ngày hoạt động'
        : 'Out date must be after the active date';
    }

    public static get UPDATED_OK_MSG() {
        if (SessionData.Language === 'vi') {
            return 'Cập nhật thành công';
        } else {
            return 'Update successful';
        }
    }
    public static get DELETED_OK_MSG() {
        return SessionData.Language === 'vi' ? 'Xóa thành công' : 'Deleted Successfully';
    }
    public static get DELETE_OK_VEHICLES_MSG() {
        return SessionData.Language === 'vi' ? 'Xóa phương tiện thành công' : 'Deleted vehicles Successfully';
    }
    public static get MESSAGE_SEND() {
        return SessionData.Language === 'vi' ? 'Tin nhắn đã được gửi' : 'Message send';
    }
    public static get LOADD_DATA_OK_MSG() {
        return SessionData.Language === 'vi' ? 'Tải dữ liệu thành công' : 'Load data successfully';
    }
    public static get FORBIDDEN() {
        return SessionData.Language === 'vi' ? 'Bạn bị chặn truy cập' : 'You are blocked from access';
    }
    public static get WARNING() {
        return SessionData.Language === 'vi' ? 'Vui lòng chọn đối tượng cần thao tác.' : 'Please select the object to manipulate.';
    }
    public static get DuAnNhomAHoacQuanTrongQuocGia() {
        return SessionData.Language === 'vi'
          ? 'Hãy chọn dự án thuộc Nhóm A hoặc nhóm Quan trọng quốc gia!' : 'Choose a project of Group A or National Important group!';
    }
    public static get XUATBAOCAO_SUCCESS() {
        return SessionData.Language === 'vi' ? 'Xuất báo cáo thành công' : 'Export report successful';
    }
    public static get ERROR_CREATE_ACCOUNT() {
        return SessionData.Language === 'vi' ? 'Email hoặc số điện thoại đã tồn tại' : 'Email or phone number already exists';
    }

    public static get Confrim() {
        return SessionData.Language === 'vi' ? 'Xác nhận' : 'Confirm';
    }
    public static get txtBtnOK() {
        return SessionData.Language === 'vi' ? 'Đồng ý' : 'OK';
    }
    public static get txtBtnCancel() {
        return SessionData.Language === 'vi' ? 'Hủy' : 'Cancel';
    }
    public static get ExistApartment() {
        return SessionData.Language === 'vi' ? 'Căn hộ đã tồn tại' : 'The apartment already exists';
    }

    public static get EXIST_IN_COMPANY() {
        return SessionData.Language === 'vi'
          ? 'Email hoặc số điện thoại đã đăng ký trong công ty' : 'The email or phone already exists in company';
    }
    public static get ADD_VERSION() {
        return SessionData.Language === 'vi' ? 'Thêm phiên bản mới thành công' : 'The new version added successfully';
    }
    public static get ERROR_DELETE() {
        return SessionData.Language === 'vi' ? 'Xoá thất bại' : 'Delete fail';
    }
    public static get ERROR_EMAIL() {
      return SessionData.Language === 'vi' ? 'Email bạn nhập đã tồn tại' : 'The email you entered already exists';
    }
    public static get ERROR_PHONE1() {
      return SessionData.Language === 'vi' ? 'Số điện thoại 1 đã tồn tại.' : 'Phone number one already exists.';
    } public static get ERROR_PHONE2() {
      return SessionData.Language === 'vi' ? 'Số điện thoại 2 đã tồn tại.' : 'Phone number two already exists.';
    }
}
