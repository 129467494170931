import { NgModule  } from '@angular/core';

import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BusyModule } from 'angular2-busy';
import { NotificationService } from './core/services/notification.service';
import { Select2Module } from 'ng2-select2';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule  } from 'ckeditor4-angular';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DataService } from './core/services/data.service';
import { AuthenService } from './core/services/authen.service';

import { UtilityService } from './core/services/utility.service';
import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2CompleterModule } from 'ng2-completer';
import { Ng2OrderModule } from 'ng2-order-pipe';


import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { MessagingService } from './core/services/messaging.service';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    BusyModule,
    BrowserModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    Select2Module,
    AngularDateTimePickerModule,
    RouterModule.forRoot(appRoutes, {useHash: false}),
    CKEditorModule,
    SelectDropDownModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    A2Edatetimepicker,
    Ng2SearchPipeModule,
    Ng2CompleterModule,
    Ng2OrderModule,
  ],
  providers: [NotificationService, DataService, AuthenService, UtilityService, MessagingService, DatePipe, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

