// import { Component, OnInit } from '@angular/core';
// import { MessagingService } from "./core/services/messaging.service";
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css']
// })
// export class AppComponent implements OnInit {
//   title = 'WebBQL';
//   message;
//   constructor(private msgService : MessagingService){

//   }
//   ngOnInit(){
//     this.msgService.getPermission();
//     this.msgService.receiveMessage();
//     this.message = this.msgService.currentMessage
//   }
// }

import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Http} from '@angular/http';
import {SystemConstants} from './core/common/system.constants';
import { SessionData } from './core/common/SessionData';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'TingConnect';
  constructor(public translate: TranslateService,
              private http: Http
  ){
    this.http.get('/assets/data/system-config.json')
      .toPromise()
      .then(res => {
        return res.json();
      })
      .then(res => {
        let term = <any>res;
        if(term && term.BASE_API){
          SystemConstants.BASE_API = term.BASE_API;
        }
      });
    translate.addLangs(['vi', 'en']);
    let lang = localStorage.getItem('LANGUAGE');
    lang = lang ? lang : 'vi';
    translate.setDefaultLang(lang);
    translate.currentLang = lang;
    
    SessionData.Language = lang;
  }
}

