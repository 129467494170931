import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { SystemConstants } from '../common/system.constants';
import { LoggedInUser } from '../domain/loggedin.user';
import { LoggedReturn } from '../domain/login.return';
import { DatePipe } from '@angular/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var CryptoJS: any;
declare var $: any;

import 'rxjs/add/operator/map';
import * as Rx from 'rxjs';

@Injectable()
export class AuthenService {


  constructor(private _http: Http,
    private datePipe: DatePipe,
    private httpClient: HttpClient) { }
    private CURRENT_ACCOUNT: string;
  encrypt(message = '', key = ''){
    var result = CryptoJS.AES.encrypt(message, key);
    return result.toString();
  } 
  login(szUsername: string, szPassword: string) {
    //encode user, password
    var dataEncrypt = this.encrypt(szUsername + '|' + szPassword, SystemConstants.KEY);

    localStorage.setItem(SystemConstants.CURRENT_ACCOUNT, dataEncrypt);

    let body = JSON.stringify({ loginName: szUsername, password: szPassword });
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.post(SystemConstants.BASE_API + 'api/Account/login', body, options).map((response: Response) => {
      if (response.json().errorCode === 0) {
        let resUser = response.json();
        let user = new LoggedInUser(
          resUser.token,
          resUser.info.id,
          resUser.info.userName,
          '',
          resUser.info.email,
          '',
          0,
          '',
          resUser.info.phoneNumber,
          resUser.info.roles,
          '',
          null,
          '',
          0
        );
        if (user && user.token) {
          localStorage.removeItem(SystemConstants.CURRENT_USER);
          localStorage.setItem(SystemConstants.CURRENT_USER, JSON.stringify(user));
        }
      }
      return response.json();
    });
  }
  loginAddEmployee(szUsername: string, szPassword: string) {
    let body = JSON.stringify({ loginName: szUsername, password: szPassword });
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.post(SystemConstants.BASE_API + 'api/Account/login', body, options).map((response: Response) => {
      if (response.json().errorCode === 0) {
        let resUser = response.json();
        let user = new LoggedInUser(
          resUser.token,
          resUser.info.id,
          resUser.info.userName,
          '',
          resUser.info.email,
          '',
          0,
          '',
          resUser.info.phoneNumber,
          resUser.info.roles,
          '',
          null,
          '',
          0
        );
      }
      return response.json();
    });
  }
  private extractData(res: Response) {
    if (res.text()) {
      let body = res.json();
      return body;
    }
    return {};
  }
  logout() {

  }
  isUserAuthenticated(): boolean {
    let user = localStorage.getItem(SystemConstants.CURRENT_USER);
    if (user != null) {
      return true;
    }
    else
      return false;
  }

  changeStatus(): any {

  }

  getLoggedInUser(): any {
    let user: LoggedInUser;
    if (this.isUserAuthenticated()) {
      var userData = JSON.parse(localStorage.getItem(SystemConstants.CURRENT_USER));
      user = new LoggedInUser(
        userData.token,
        userData.tingId,
        userData.userName,
        userData.fullName,
        userData.email,
        userData.avatar,
        userData.status,
        userData.mood,
        userData.phone,
        userData.roles,
        userData.empId,
        userData.contactMessage,
        userData.departmentId,
        userData.isEmployee);
    }
    else
      user = null;
    return user;
  }
  encodeUser() {
    let user_account_encode = localStorage.getItem(SystemConstants.CURRENT_ACCOUNT);
    return user_account_encode;
  }
}
