export class LoggedInUser {
    constructor(token: string, 
        tingId: string, 
        userName: string, 
        fullName: string, 
        email: string, 
        avatar: string, 
        status: number, 
        mood: string,
        phone: string, 
        roles: any, 
        empId : string, 
        contactMessage: any, 
        departmentId: string,
        isEmployee: number) {
        this.token = token;
        this.tingId = tingId
        this.fullName = fullName;
        this.userName = userName;
        this.email = email;
        this.avatar = avatar;
        this.status = status;
        this.mood = mood;
        this.phone = phone;
        this.roles = roles;
        this.empId = empId;
        this.contactMessage = contactMessage;
        this.departmentId = departmentId;
        this.isEmployee = isEmployee;
    }
    public id: string;
    public token: string;
    public tingId: string;
    public userName: string;
    public fullName: string;
    public email: string;
    public avatar: string;
    public status: number;
    public mood: string;
    public phone : string;
    public roles: any;
    public empId: string;
    public contactMessage : any;
    public departmentId : string;
    public isEmployee : number;
}