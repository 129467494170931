export class UrlConstants {
    public static LOGIN = '/login';
    public static ERROR = '/main/error/index';
    public static HOME = '/main/home';
    public static ISSUE = '/main/issues/issues';
    public static SCHEDULETASK = '/main/taskschedule';
    public static EMPLOYEE = '/main/employees/employees';
    public static TIMEKEEPING = '/main/employees/timekeeping';
    public static CUSTOMER = '/main/customers/maincustomers';
    public static VERSION = "/main/versions";
}
