import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UrlConstants } from '../common/url.constants';
import { AuthenService } from './authen.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class UtilityService {
  private _router: Router;

  constructor(router: Router, private http: Http, private authenService: AuthenService,
    public translate: TranslateService) {
    this._router = router;
  }

  convertDateTime(date: Date) {
    var _formattedDate = new Date(date.toString());
    return _formattedDate.toDateString();
  }

  navigate(path: string) {
    this._router.navigate([path]);
  }
  navigateToLogin() {
    this._router.navigate([UrlConstants.LOGIN]);
  }

  navigateToError() {
      this._router.navigate([UrlConstants.ERROR]);
  }

  BuildTree = (arr: any[]): any[] => {
    let roots: any[] = [];
    roots = arr.filter(x => x.ParentId == null);
    for (var i = 0; i < roots.length; i++) {
      this.Tree(arr, roots[i]);
    }
    return roots;
  }

  Tree(arr: any[], list: any) {
    let childs = arr.filter(x => x.ParentId == list.ID);
    //list.data.expanded = true;
    list.children = childs;
    for (var i = 0; i < childs.length; i++) {
      this.Tree(arr, childs[i]);
    }
  }

  Unflatten = (arr: any[]): any[] => {
      let map = {};
      let roots: any[] = [];
      for (var i = 0; i < arr.length; i += 1) {
          let node = arr[i];
          node.children = [];
          map[node.ID] = i; // use map to look-up the parents
          if (node.ParentId !== null) {
              arr[map[node.ParentId]].children.push(node);
          } else {
              roots.push(node);
          }
      }
      return roots;
  }
  datepickerOption = (startDate: Date, endDate: Date): any => {
    let _startDate = new Date(2010, 1, 1);
    let _endDate = new Date(2030, 1, 1);
    if (startDate != null) {
      _startDate = startDate;
    }
    if (endDate != null) {
      _endDate = endDate;
    }
    return {
      startDate: _startDate,
      endDate: _endDate,
      minDate: _startDate,
      maxDate: _endDate,
      autoclose: true,
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'dd/mm/yyyy',
      icon: 'fa fa-calendar',
      language: 'vi',
      placeholder: '',
      orientation: 'bottom',
      enableOnReadonly: false
    };
  }
  datepickerOptionNew = (startDate: Date, endDate: Date, text: string): any => {
    let _startDate = new Date(2010, 1, 1);
    let _endDate = new Date(2030, 1, 1);
    if (startDate != null) {
      _startDate = startDate;
    }
    if (endDate != null) {
      _endDate = endDate;
    }
    let trans : any = this.translate.get(text);
    return {
      startDate: _startDate,
      endDate: _endDate,
      autoclose: true,
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'dd/mm/yyyy',
      icon: 'fa fa-calendar',
      language: 'vi',
      placeholder: trans.value,
      orientation: 'bottom',
      enableOnReadonly: false
    };
  }

  datepickerOpts = {
    startDate: new Date(2000, 1, 1),
    endDate: new Date(2030, 1, 1),
    autoclose: true,
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd/mm/yyyy',
    icon: 'fa fa-calendar',
    language: 'vi',
    placeholder: 'Chọn ngày',
    enableOnReadonly: false
  };


  filesCongVan = (file: String): any[]=>{
    let filearray = null;
    if(file.length > 0){
      filearray = file.split('|');
    }
    return filearray;
  };


  MakeSeoTitle(input: string) {
    if (input === undefined || input === '')
      return '';
    //Đổi chữ hoa thành chữ thường
    var slug = input.toLowerCase();

    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');

    return slug;
  }
  numberTel = createNumberMask({

  });
  numberMaskDot = createNumberMask({
    prefix: '',
    suffix: '',
    thousandsSeparatorSymbol: '',
    decimalSymbol: '',
    decimalLimit: 2,
    allowDecimal: true,
    allowNegative: true
  });
  numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    thousandsSeparatorSymbol: ',',
    decimalSymbol: '.',
    decimalLimit: 2,
    allowDecimal: true,
    // allowNegative: true
  });
  UnMaskTienTe = (Decimal: string): number => {
    if (Decimal != null) {
      Decimal = Decimal.toString().replace(/[.]+/g, '');
      return parseFloat(Decimal.replace(/[,]+/g, '.'));
    }
  }

  MaskTienTe = (Decimal: number): string => {
    if (Decimal != null) {
      return Decimal.toString().replace(/[.]+/g, ',');
    }
  }
}
