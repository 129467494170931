import { Injectable } from '@angular/core';
import { SessionData } from '../common/SessionData';
import { MessageContstants } from '../common/message.constants';
declare var alertify: any;

@Injectable()
export class NotificationService {
  private _notifier: any = alertify;
  constructor() {
    //alertify.confirm().set('reverseButtons', true);
    alertify.defaults = {
      // dialogs defaults
      autoReset: true,
      basic: false,
      closable: true,
      closableByDimmer: true,
      frameless: false,
      maintainFocus: true, // <== global default not per instance, applies to all dialogs
      maximizable: true,
      modal: true,
      movable: false,
      moveBounded: false,
      overflow: true,
      padding: true,
      pinnable: true,
      pinned: true,
      reverseButtons: true,
      preventBodyShift: false, // <== global default not per instance, applies to all dialogs
      resizable: true,
      startMaximized: false,
      // transition: 'pulse',
      // notifier defaults
      notifier: {
        // auto-dismiss wait time (in seconds)
        delay: 5,
        // default position
        position: 'bottom-right',
        // adds a close button to notifier messages
        closeButton: false
      },

      // language resources
      glossary: {
        // dialogs default title
        title: MessageContstants.Confrim,
        cancel: MessageContstants.txtBtnCancel,
        // ok button text
        ok: MessageContstants.txtBtnOK
        // cancel button text
      },

      // theme settings
      theme: {
        // class name attached to prompt dialog input textbox.
        input: 'ajs-input',
        cancel: 'btn-style2',
        // class name attached to ok button
        // ok: 'ajs-ok',
        ok: 'btn-style1'
        // class name attached to cancel button
        // cancel: 'ajs-cancel'
      }
    };

  }


  printSuccessMessage(message: string) {

    this._notifier.success(message);
  }

  printErrorMessage(message: string) {
    this._notifier.error(message);
  }
  prinWarningMessage(message: string){
    this._notifier.warning(message);
  }

  printConfirmationDialog(message: string, okCallback: () => any) {
    this._notifier.confirm(message, function (e) {
      if (e) {
        okCallback();
      } else {
      }
    }).set('reverseButtons', true);
  }

}
